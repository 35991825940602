import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import WebSocketClient from './Components/WebSocketClient';
import ChartComponent from './Components/ChartComponent';
import useWebSocketDataStore from "./Store/WebSocketDataStore.jsx";
import TablesComponent from "./Components/TablesComponent";
import DatePickerComponent from "./Components/DatePickerComponent";
import CorrelationComponent from "./Components/TrendComponent.jsx";
import './App.css';

function App() {
  const { data, callData, putData } = useWebSocketDataStore();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1080);

  useEffect(() => {
    // Initialize Google Analytics
    // ReactGA.initialize('G-3T651JWGNW');
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1080);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <WebSocketClient />

      {/* DatePickerComponent */}
      <DatePickerComponent />

      <div style={{ width: '100%', padding: '0px' }}>
        <div style={{ marginBottom: '0px' }}>
          <ChartComponent data={data} />
        </div>
        <div style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '0px',
        }}>
          <CorrelationComponent />
          <div style={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            justifyContent: 'space-between',
            gap: '20px',
          }}>
            <div style={{ flex: 1 }}>
              <TablesComponent callData={callData} putData={putData} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );

}

export default App;






