import { create } from 'zustand';

const initializeVariableToggles = (currentToggles, newData) => {
  const defaultVisibility = {
    total_gex: true,
    total_call_volume_gamma: true,
    total_put_volume_gamma: true,
    total_call_volume_vega: false,
    total_put_volume_vega: false,
    total_call_volume_delta: false,
    total_put_volume_delta: false,
    total_call_volume_theta: false,
    total_put_volume_theta: false,
  };

  const updatedToggles = { ...currentToggles };

  newData.forEach(item => {
    Object.keys(item).forEach(key => {
      if (updatedToggles[key] === undefined) {
        updatedToggles[key] = defaultVisibility[key] !== undefined ? defaultVisibility[key] : true;
      }
    });
  });

  return updatedToggles;
};

const useWebSocketDataStore = create(set => ({
  data: [],
  toggles: { ...initializeVariableToggles({}, []) },
  callData: [],
  putData: [],
  correlations: {
    correlations20: [],
    correlations30: [],
    correlations40: [],
  },
  isReplaying: false,
  replayIndex: 0,
  replaySpeed: 200,
  replayTimer: null,

  setData: newData => {
    set(state => {
      const updatedToggles = { ...initializeVariableToggles(state.toggles, newData) };
      return {
        data: newData,
        toggles: updatedToggles,
      };
    });
  },

  addData: newDataRow => set(state => {
    const exists = state.data.some(item => item.time === newDataRow.time);
    let newData = exists ? state.data : [...state.data, newDataRow];
    const updatedToggles = { ...initializeVariableToggles(state.toggles, newData) };
    return {
      data: newData,
      toggles: updatedToggles,
    };
  }),

  appendData: newDataArray => set(state => {
    const updatedData = [...newDataArray];
    const updatedToggles = { ...initializeVariableToggles(state.toggles, updatedData) };
    return {
      data: updatedData,
      toggles: updatedToggles,
    };
  }),

  setToggle: (key, value) => set(state => ({
    ...state,
    toggles: { ...state.toggles, [key]: value },
  })),

  setOptionsData: newOptionsData => set(() => {
    const newCallData = newOptionsData
      .filter(item => item.option_type === 'call')
      .slice(0, 10);

    const newPutData = newOptionsData
      .filter(item => item.option_type === 'put')
      .slice(0, 10);

    return {
      optionsData: newOptionsData,
      callData: newCallData,
      putData: newPutData,
    };
  }),

  setCorrelations: (newCorrelations) => set(() => ({
    correlations: newCorrelations,
  })),

  startReplay: () => set(state => {
    if (state.replayTimer) {
      clearInterval(state.replayTimer);
    }

    const startFromBeginning = state.replayIndex >= state.data.length - 1;
    const newReplayIndex = startFromBeginning ? 0 : state.replayIndex;

    const timer = setInterval(() => {
      set(s => {
        if (s.replayIndex < s.data.length - 1) {
          return { replayIndex: s.replayIndex + 1 };
        } else {
          clearInterval(timer);
          return { isReplaying: false, replayTimer: null };
        }
      });
    }, state.replaySpeed);

    return { isReplaying: true, replayIndex: newReplayIndex, replayTimer: timer };
  }),

  stopReplay: () => set(state => {
    clearInterval(state.replayTimer);
    return { isReplaying: false, replayTimer: null };
  }),

  setReplaySpeed: (speed) => set(state => {
    if (state.replayTimer) {
      clearInterval(state.replayTimer);
    }

    const newTimer = state.isReplaying ? setInterval(() => {
      set(s => {
        if (s.replayIndex < s.data.length - 1) {
          return { replayIndex: s.replayIndex + 1 };
        } else {
          clearInterval(newTimer);
          return { isReplaying: false, replayTimer: null };
        }
      });
    }, speed) : null;

    return { replaySpeed: speed, replayTimer: newTimer };
  }),

  setReplayIndex: (index) => set(() => ({
    replayIndex: index,
  })),

  sendHistoricalDataRequest: (date) => {
    console.warn('sendHistoricalDataRequest function not yet set.', date);
  },

  setSendHistoricalDataRequest: (callbackFn) => set(() => ({
    sendHistoricalDataRequest: callbackFn,
  })),
}));

export default useWebSocketDataStore;










