import React, { useState, useEffect } from 'react';
import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';

const DatePickerComponent = () => {
    const [selectedDate, setSelectedDate] = useState('2024-01-02');
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1080);

    const {
        sendHistoricalDataRequest,
        startReplay,
        stopReplay,
        setReplaySpeed,
        setReplayIndex
    } = useWebSocketDataStore();

    useEffect(() => {
        sendHistoricalDataRequest(selectedDate);
    }, [sendHistoricalDataRequest, selectedDate]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 1080);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDateChange = (event) => {
        const newDate = event.target.value;
        setSelectedDate(newDate);

        if (newDate) {
            sendHistoricalDataRequest(newDate);
            stopReplay();
            setReplayIndex(0); // Reset replayIndex to 0
        }
    };

    const handleStartReplay = () => {
        startReplay();
    };

    const handleStopReplay = () => {
        stopReplay();
    };

    const handleSpeedChange = (event) => {
        setReplaySpeed(parseInt(event.target.value, 10));
    };

    const buttonAndInputStyle = {
        padding: isSmallScreen ? '5px' : '10px',
        borderRadius: '5px',
        fontSize: isSmallScreen ? '12px' : '16px',
        cursor: 'pointer',
        backgroundColor: '#B18800',
        border: 'none',
        color: 'white',
        margin: '5px',
        width: isSmallScreen ? '30%' : 'auto'
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '0px',
            color: 'white', // Explicitly set text color to white
            marginTop: '5px',
            marginBottom: '5px',
        }}>
            <div style={{ fontWeight: 'bold', color: 'white' }}>
                <h2>Historical Replay</h2>
            </div>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexWrap: 'nowrap' // changed to nowrap
            }}>
                <input
                    type="date"
                    value={selectedDate}
                    onChange={handleDateChange}
                    min="2024-01-02"
                    style={buttonAndInputStyle}
                />
                <button
                    onClick={handleStartReplay}
                    style={buttonAndInputStyle}
                    aria-label="Play"
                >
                    ▶
                </button>
                <button
                    onClick={handleStopReplay}
                    style={buttonAndInputStyle}
                    aria-label="Pause"
                >
                    ⏸
                </button>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span style={{ marginLeft: '10px', marginRight: '0px', fontSize: isSmallScreen ? '12px' : '14px', color: 'white' }}>Speed:</span>
                    <input
                        type="number"
                        id="replaySpeed"
                        defaultValue="200"
                        onChange={handleSpeedChange}
                        style={buttonAndInputStyle}
                    />
                </div>
            </div>
        </div>
    );

};

export default DatePickerComponent;






