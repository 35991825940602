import React, { useEffect } from 'react';
import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx";
import moment from 'moment-timezone';

// const WebSocketClient = () => {
//   const { appendData, setOptionsData, setSendHistoricalDataRequest } = useWebSocketDataStore();

//   useEffect(() => {
//      // Use environment variable for WebSocket URL
//     const wsURL = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3002';
//     const socket = new WebSocket(wsURL);

//     socket.addEventListener('open', () => {
//       console.log('Connected to WebSocket');

//       const sendRequest = (date) => {
//         if (socket.readyState === WebSocket.OPEN) {
//           socket.send(JSON.stringify({ type: 'historical-data', date }));
//         }
//       };

//       setSendHistoricalDataRequest(sendRequest);
//     });
 const WebSocketClient = () => {
   const { appendData, setOptionsData, setSendHistoricalDataRequest } = useWebSocketDataStore();

   useEffect(() => {
     // Use environment variable for WebSocket URL
     const wsURL = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3002';
     const socket = new WebSocket(wsURL);

     socket.addEventListener('open', () => {
       console.log('Connected to WebSocket');

       const sendRequest = (date) => {
         if (socket.readyState === WebSocket.OPEN) {
           socket.send(JSON.stringify({ type: 'historical-data', date }));
         }
       };

       setSendHistoricalDataRequest(sendRequest);
     });

    socket.addEventListener('message', (event) => {
      const message = JSON.parse(event.data);
      console.log('Data received:', message);

      // Function to adjust Unix timestamps to local time
      const adjustTimeToLocalUnix = (estTime) => {
        const cleanedEstTime = estTime.replace('Z', '');
        const estMoment = moment.tz(cleanedEstTime, "YYYY-MM-DDTHH:mm:ss.SSS", "America/New_York");
        const estUnixTimestamp = estMoment.unix();

        const adjustUnixTimeToLocal = (unixTimestamp) => {
          const date = new Date(unixTimestamp * 1000);
          const localOffset = date.getTimezoneOffset() * 60; // Offset in seconds
          return unixTimestamp - localOffset;
        };

        return adjustUnixTimeToLocal(estUnixTimestamp);
      };

      // Process SPXGAMMA data
      if (message.greekData && Array.isArray(message.greekData)) {
        const adjustedGreekData = message.greekData.map(item => ({
          ...item,
          time: adjustTimeToLocalUnix(item.time)
        }));

        // Remove duplicate timestamps
        const uniqueGreekData = adjustedGreekData.reduce((accumulator, current) => {
          const duplicate = accumulator.find(item => item.time === current.time);
          if (!duplicate) {
            accumulator.push(current);
          }
          return accumulator;
        }, []);

        appendData(uniqueGreekData);
      }

      // Process OPTIONS_DATA
      if (message.optionsData && Array.isArray(message.optionsData)) {
        const adjustedOptionsData = message.optionsData.map(item => ({
          ...item,
          timestamp: item.timestamp ? adjustTimeToLocalUnix(item.timestamp) : item.timestamp
        }));
        setOptionsData(adjustedOptionsData);
      }
    });

    socket.addEventListener('close', () => {
      console.log('WebSocket connection closed');
    });

    return () => {
      socket.close();
    };
  }, [appendData, setOptionsData, setSendHistoricalDataRequest]);

  return null;
};

export default WebSocketClient;

// import React, { useEffect } from 'react';
// import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx";

// const WebSocketClient = () => {
//   const { appendData, setOptionsData, setSendHistoricalDataRequest } = useWebSocketDataStore();

//   useEffect(() => {
//     // Use environment variable for WebSocket URL
//     const wsURL = process.env.REACT_APP_WEBSOCKET_URL || 'ws://localhost:3002';
//     const socket = new WebSocket(wsURL);

//     socket.addEventListener('open', () => {
//       console.log('Connected to WebSocket');

//       const sendRequest = (date) => {
//         if (socket.readyState === WebSocket.OPEN) {
//           socket.send(JSON.stringify({ type: 'historical-data', date }));
//         }
//       };

//       setSendHistoricalDataRequest(sendRequest);
//     });

//     socket.addEventListener('message', (event) => {
//       const message = JSON.parse(event.data);
//       console.log('Data received:', message);

//       // Function to adjust Unix timestamps to local time
//       const adjustUnixTimeToLocal = (unixTimestamp) => {
//         const date = new Date(unixTimestamp * 1000);
//         const localOffset = date.getTimezoneOffset() * 60; // Offset in seconds
//         return unixTimestamp - localOffset;
//       };

//       // Process SPXGAMMA data
//       if (message.spxGammaData && Array.isArray(message.spxGammaData)) {
//         const adjustedSPXGammaData = message.spxGammaData.map(item => ({
//           ...item,
//           time: adjustUnixTimeToLocal(item.time)
//         }));

//         // Remove duplicate timestamps
//         const uniqueSPXGammaData = adjustedSPXGammaData.reduce((accumulator, current) => {
//           const duplicate = accumulator.find(item => item.time === current.time);
//           if (!duplicate) {
//             accumulator.push(current);
//           }
//           return accumulator;
//         }, []);

//         appendData(uniqueSPXGammaData);
//       }

//       // Process OPTIONS_DATA
//       if (message.optionsData && Array.isArray(message.optionsData)) {
//         const adjustedOptionsData = message.optionsData.map(item => ({
//           ...item,
//           server_timestamp: item.server_timestamp ? adjustUnixTimeToLocal(item.server_timestamp) : item.server_timestamp
//         }));
//         setOptionsData(adjustedOptionsData);
//       }
//     });

//     socket.addEventListener('close', () => {
//       console.log('WebSocket connection closed');
//     });

//     return () => {
//       socket.close();
//     };
//   }, [appendData, setOptionsData, setSendHistoricalDataRequest]);

//   return null;
// };

// export default WebSocketClient;













