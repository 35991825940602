import React, { useState, useEffect } from 'react';
import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';
import './Tables.css';

const TablesComponent = () => {
  const { optionsData, callData, putData, isReplaying, replayIndex } = useWebSocketDataStore();
  const [callTableData, setCallTableData] = useState([]);
  const [putTableData, setPutTableData] = useState([]);

  useEffect(() => {
    const getOptionsDataForReplay = () => {
      if (!optionsData || optionsData.length === 0) {
        // If optionsData is undefined or empty, set empty arrays for the table data
        setCallTableData([]);
        setPutTableData([]);
        return;
      }

      const startIndex = replayIndex * 20; // 20 entries per step
      const endIndex = startIndex + 20;

      // Ensure endIndex does not exceed the length of optionsData
      const safeEndIndex = Math.min(endIndex, optionsData.length);

      const currentData = optionsData.slice(startIndex, safeEndIndex);
      const currentCallData = currentData.filter(item => item.option_type === 'call');
      const currentPutData = currentData.filter(item => item.option_type === 'put');

      setCallTableData(currentCallData);
      setPutTableData(currentPutData);
    };

    if (isReplaying || (optionsData && optionsData.length > replayIndex * 20)) {
      getOptionsDataForReplay();
    } else {
      // Use the latest data if not replaying or if optionsData is not sufficient
      setCallTableData(callData ? callData.slice(-10) : []);
      setPutTableData(putData ? putData.slice(-10) : []);
    }
  }, [optionsData, callData, putData, isReplaying, replayIndex]);



  return (
    <div className="tables-container">
      <div className="table">
        <h2>Calls</h2>
        <table>
          <thead>
            <tr>
              <th>Strike</th>
              <th>SPX Gex</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {callTableData.map((item, index) => (
              <tr key={index}>
                <td>{item.strike}</td>
                <td>{item.volume_gamma}</td>
                <td>{item.volume}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="table">
        <h2>Puts</h2>
        <table>
          <thead>
            <tr>
              <th>Strike</th>
              <th>SPX Gex</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {putTableData.map((item, index) => (
              <tr key={index}>
                <td>{item.strike}</td>
                <td>{item.volume_gamma}</td>
                <td>{item.volume}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TablesComponent;

