import React from 'react';
import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx";

const SeriesToggleButton = ({ seriesKey, label, className }) => {
  const { toggles, setToggle } = useWebSocketDataStore(state => ({
    toggles: state.toggles,
    setToggle: state.setToggle
  }));

  const handleClick = () => {
    setToggle(seriesKey, !toggles[seriesKey]);
  };

  return (
    <label className={`checkbox-container ${className}`}>
      {label}
      <input
        type="checkbox"
        checked={toggles[seriesKey]}
        onChange={handleClick}
      />
      <span className="checkmark"></span>
    </label>
  );
};

export default SeriesToggleButton;

