import React, { useEffect, useRef, useState } from 'react';
import useWebSocketDataStore from "../Store/WebSocketDataStore.jsx"
import { createChart, CrosshairMode, PriceScaleMode, LineStyle} from 'lightweight-charts';
import SeriesToggleButton from "../Components/SeriesToggleButton.jsx";
import './Style.css'; // Import your CSS file


// Define series configurations for call-leading and put-leading trends
const TRENDSERIES_CALL_LEADING = {
    key: 'callLeadingSeries',
    type: 'area',
    scale: 0,
    // buttonLabel: 'GTI (Call Leading)',
    chartOptions: {
        // title: 'GTS - Call Leading',
        topColor: 'rgba(2,204,253,0.6)',
        // bottomColor: 'rgba(255, 0, 102, 0.8)',
        bottomColor: 'rgba(245,236,7,0.8)',
        lineColor: 'transparent', //
        lineWidth: 1,
        priceScaleId: 'right',
        priceLineVisible: false,
        lastValueVisible: false,
    },
};

const TRENDSERIES_PUT_LEADING = {
    key: 'putLeadingSeries',
    type: 'area',
    scale: 0,
    // buttonLabel: 'GTI (Put Leading)',
    chartOptions: {
        // title: 'GTS - Put Leading',
        bottomColor: 'rgba(245,236,7,0.8)',
        topColor: 'rgb(255,0,0, 0.6)',
        lineColor: 'transparent',
        lineWidth: 1,
        priceScaleId: 'right',
        priceLineVisible: false,
        lastValueVisible: false,
    },
};

const HORIZONTAL_LINES = [
    { price: 0.2, color: 'white', lineWidth: 5  } ,// Adjusted threshold line for "No Trend"
    { price: .5, color: 'rgba(0, 0, 0, 0)' },
];


//Support and resistance line series.
const createSRSeries = (optionsData, replayIndex) => {
  // Access the data array from the Zustand store
  const data = useWebSocketDataStore.getState().data;

  // Ensure replayIndex is within the bounds of the data array
  const indexWithinBounds = replayIndex >= 0 && replayIndex < data.length;

  // Access the close value for the current replayIndex, if within bounds
  const closeValue = indexWithinBounds ? data[replayIndex].close : null;

  console.log('Close Value for Replay Index from Store:', closeValue);

  const createSeriesConfig = (key, price, color, title) => ({
    key,
    type: 'line',
    scale: 2,
    chartOptions: {
      color,
      lineWidth: 1,
      lineStyle: LineStyle.Dashed,
      axisLabelVisible: false,
      title,
    },
    price  // Only include the strike price
  });

  // Calculate the start and end index for slicing optionsData
  const startIndex = replayIndex * 20; // Assuming 20 entries per index
  const endIndex = startIndex + 20;

  // Slice and filter optionsData for calls and puts
  const currentData = optionsData.slice(startIndex, endIndex);
  const currentCallData = currentData.filter(item => item.option_type === 'call');
  const currentPutData = currentData.filter(item => item.option_type === 'put');

  // Further filter for rows 6-10 for calls and puts
  const filteredCallData = currentCallData.slice(5, 10); // Rows 6-10 have index 5-9
  const filteredPutData = currentPutData.slice(5, 10);

  // Sort and slice top 2 calls and puts
  const topCalls = currentCallData.slice(0, 2);
  const topPuts = currentPutData.slice(0, 2);

  // Create series for the top calls and puts
  const callSeries = topCalls.map((item, index) =>
    createSeriesConfig(`Call${index + 1}`, item.strike, 'green', `Call ${index + 1}`)
  );
  const putSeries = topPuts.map((item, index) =>
    createSeriesConfig(`Put${index + 1}`, item.strike, 'red', `Put ${index + 1}`)
  );

  // Initialize variables for highest volume call and put series
  let highestVolumeCallSeries = null, highestVolumePutSeries = null;

  // Ensure there are items before trying to find the highest volume
  if (filteredCallData.length > 0 && filteredCallData.some(item => item.strike > closeValue)) {
    const highestVolumeCall = filteredCallData
      .filter(item => item.strike > closeValue)
      .reduce((prev, current) => (prev.volume > current.volume) ? prev : current);

    highestVolumeCallSeries = createSeriesConfig('HighestVolumeCall', highestVolumeCall.strike, '#B18800', 'Dynamic Day High');
  } else {
    console.log('No highest volume call found in the specified range or above close value.');
  }

  if (filteredPutData.length > 0 && filteredPutData.some(item => item.strike < closeValue)) {
    const highestVolumePut = filteredPutData
      .filter(item => item.strike < closeValue)
      .reduce((prev, current) => (prev.volume > current.volume) ? prev : current);

    highestVolumePutSeries = createSeriesConfig('HighestVolumePut', highestVolumePut.strike, '#b18800', 'Dynamic Day Low');
  } else {
    console.log('No highest volume put found in the specified range or above close value.');
  }

  // Compile all series, including or excluding highest volume series based on availability
  return [
    ...callSeries,
    ...putSeries,
    ...(highestVolumeCallSeries ? [highestVolumeCallSeries] : []),
    ...(highestVolumePutSeries ? [highestVolumePutSeries] : [])
  ];
};



//Series for the variables
const SERIES = [
    // Candlestick series
    {
      key: 'candlestick',
      type: 'candlestick',
      scale: 0,
      buttonLabel: 'Candles',
      chartOptions: {
        priceScaleId: 'right',
        color: '#838ca1',
        upColor: 'transparent', // Hollow candlesticks for upward movement
        downColor: 'transparent', // Hollow candlesticks for downward movement
        borderUpColor: '#B18800', // Yellow border for downward movement
        borderDownColor: '#838ca1', // Green border for upward movement
        wickDownColor: '#838ca1', // Wick color for downward movement
        wickUpColor: '#838ca1', // Wick color for upward movement
        priceLineVisible: false,
        // If available, set border width here
      }
    },
    // Call Delta
    {
      key: 'total_call_volume_delta',
      type: 'line',
      scale: 2,
      buttonLabel: 'Call Delta',
      chartOptions: {
        title: 'Call Theta',
        color: '#ff00e6',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,

      }
    },
      // Put Delta
    {
      key: 'total_put_volume_delta',
      type: 'line',
      scale: 2,
      buttonLabel: 'Put Delta',
      chartOptions: {
        title: 'Put Delta',
        color: '#7b70fa',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,
      }
    },
   //Call GEX
    {
      key: 'total_call_volume_gamma',
      type: 'line',
      scale:  0,
      buttonLabel: 'Call GEX',
      chartOptions: {
        title: 'Call GEX',
        lineWidth: 1,
        priceScaleId: 'left',
        color: '#00ff00',
        priceLineVisible: false,
      }
    },

   //  Put GEX
    {
      key: 'total_put_volume_gamma',
      type: 'line',
      scale: 0,
      buttonLabel: 'Put GEX',
      chartOptions: {
        title: 'Put GEX',
        color: 'rgb(241,0,0)',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,

      }
    },

    // Total GEX
    {
      key: 'total_gex',
      type: 'area',
      scale: 3,
      buttonLabel: 'Total GEX',
      chartOptions: {
        title: 'Total GEX',
        topColor: 'rgb(255,255,255)',
        lineColor: 'rgb(255,255,255)',
        bottomColor: 'rgba(103,103,103,0)',
        lineWidth: 1,
        priceScaleId: 'left',
        priceLineVisible: false,

      }
    },
   // Call Theta
     {
       key: 'total_call_volume_theta',
       type: 'line',
       scale: 2,
       buttonLabel: 'Call Charm',
       chartOptions: {
         title: 'Call Charm',
         lineWidth: 1,
         priceScaleId: 'left',
         color: '#00ffff',
         priceLineVisible: false,
       }
     },
     // Put Theta
     {
       key: 'total_put_volume_theta',
       type: 'line',
       scale: 2,
       buttonLabel: 'Put Charm',
       chartOptions: {
         title: 'Put Charm',
         lineWidth: 1,
         priceScaleId: 'left',
         color: '#ff6a00',
         priceLineVisible: false,
       }
     },

    // Call Vega
     {
       key: 'total_call_volume_vega',
       type: 'line',
       scale: 2,
       buttonLabel: 'Call Vanna',
       chartOptions: {
         title: 'Call Vanna',
         color: '#0055ff',
         lineWidth: 1,
         priceScaleId: 'left',
         priceLineVisible: false,
       }
     },
     // Put Vega
     {
       key: 'total_put_volume_vega',
       type: 'line',
       scale: 2,
       buttonLabel: 'Put Vanna',
       chartOptions: {
         title: 'Put Vanna',
         color: '#ffaa00',
         lineWidth: 1,
         priceScaleId: 'left',
         priceLineVisible: false,
       }
     },
]
const ChartComponent = () => {
  //const data = useWebSocketDataStore((state) => state.data);
  const { optionsData, data, toggles, callData, putData, isReplaying, replayIndex, correlations,  replaySpeed } = useWebSocketDataStore(state => ({
    data: state.data,
    toggles: state.toggles,
    optionsData: state.optionsData,
    callData: state.callData,
    putData: state.putData,
    isReplaying: state.isReplaying,
    replayIndex: state.replayIndex,
    replaySpeed: state.replaySpeed,
    correlations: state.correlations
  }));


  const chartContainerRef = useRef(null);
  const trendChartContainerRef = useRef(null);
  const chartRef = useRef(null);
  const trendChartRef = useRef(null);
  const seriesRef = useRef({});
  const trendSeriesRef = useRef({});



// Function to transform data for each series and remove duplicates
  const transformDataForSeries = (data, seriesKey) => {
    let lastNonNullValue = null;
    return data.map(item => {
      let value = item[seriesKey];

      if (value === null) {
        value = lastNonNullValue; // Use last non-null value if current is null
      } else {
        lastNonNullValue = value; // Update last non-null value
      }
      return {
        time: item.time,
        value: value // This might be the original value or the last non-null value
      };
    });
  };

  const transformDataForCandlestick = (data) => {
    const transformedData = data.map(item => ({
      time: item.time, // Assuming 'time' is your timestamp field
      open: item.open,
      high: item.high,
      low: item.low,
      close: item.close,
    }));
    return transformedData;
  };


  useEffect(() => {
    if (!data || data.length === 0 || !chartContainerRef.current)  {
      return;
    }

    if (!chartRef.current) {
      chartRef.current = createChart(chartContainerRef.current, {
        width: window.innerWidth * .95,
        height: window.innerHeight * .80,
        alignLabels: true,
        timeScale: {
         rightOffset:12,
         barSpacing: 1,
         shiftVisibleRangeOnNewBar: false,
         fixLeftEdge: true,
         rightBarStaysOnScroll: true,
         leftBarStaysOnScroll: true,
         borderVisible: false,
         borderColor: "#000000",
         timeVisible: true,
         secondsVisible: false,

        },
        leftPriceScale: {
         autoScale: true,
         borderVisible: false,
         visible: true,
         mode: PriceScaleMode.Normal, // Enable manual scaling
       },
        rightPriceScale: {
         autoScale: true,
         borderVisible: false,
         mode: PriceScaleMode.Normal,
      },
       grid: {
        vertLines: {
          color: 'rgba(42, 46, 57, 0)',
        },
        horzLines: {
          color: 'rgba(87,87,87,0.23)',
        },
      },
      layout: {
        background: {
          color: '#000000'
        },
        textColor: '#d1d4dc',
      },
      priceScale: {
        autoScale: true,
      },
      crosshair: {
            mode: CrosshairMode.Normal,
            // Vertical crosshair line (showing Date in Label)
            vertLine: {
                width: 8,
                color: '#C3BCDB44',
                style: LineStyle.Solid,
                labelBackgroundColor: '#B18800',
            },

            // Horizontal crosshair line (showing Price in Label)
            horzLine: {
                color: '#C3BCDB44',
                labelBackgroundColor: '#B18800',
            },
        },

     });
    //chartRef.current.timeScale().fitContent();
    chartRef.current.timeScale().setVisibleLogicalRange({ from: 0, to: 410 });

  // Generate series configurations for call and put data
    const srSeriesConfigurations = createSRSeries(callData, putData);

    // Initialize series based on SERIES configuration
    SERIES.forEach(seriesInfo => {
      let series;
      if (seriesInfo.type === 'line') {
        series = chartRef.current.addLineSeries(seriesInfo.chartOptions);
        const lineSeriesData = transformDataForSeries(data, seriesInfo.key);
        series.setData(lineSeriesData);
        series.applyOptions({ visible: toggles[seriesInfo.key] });
      } else if (seriesInfo.type === 'area') {
        series = chartRef.current.addAreaSeries(seriesInfo.chartOptions);
        const areaSeriesData = transformDataForSeries(data, seriesInfo.key);
        series.setData(areaSeriesData);
        series.applyOptions({ visible: toggles[seriesInfo.key] });
      } else if (seriesInfo.type === 'candlestick') {
        series = chartRef.current.addCandlestickSeries(seriesInfo.chartOptions);
        const candlestickData = transformDataForCandlestick(data);
        series.setData(candlestickData);
      }
      // Initialize the series with visibility based on toggles
      if (series) {
        series.applyOptions({
          visible: toggles[seriesInfo.key], // Set visibility based on toggle value
        });
      }
      // Add the zero line to the series
      if (series) {
        series.createPriceLine({
          price: 0,
          color: 'white',
          lineWidth: 1,
          lineStyle: LineStyle.Solid, // Using LineStyle from the imported module
          axisLabelVisible: false,
          title: 'Zero Line',
        });
      }
      seriesRef.current[seriesInfo.key] = series;
    });
      const handleResize = () => {
          let newWidth, newHeight;

          // Check the window width and set the dimensions accordingly
          if (window.innerWidth <= 1080) {
              newWidth = window.innerWidth * 0.95;
              newHeight = window.innerHeight * 0.55;
          } else {
              newWidth = window.innerWidth * 0.95;
              newHeight = window.innerHeight * 0.55;
          }

          // Resize the chart with the new dimensions
          chartRef.current.resize(newWidth, newHeight);

          // Adjust the visible range or scroll position if needed
          const timeScale = chartRef.current.timeScale();
          timeScale.setVisibleLogicalRange({ from: 0, to: 410 });
      };

      // Add the resize event listener
      window.addEventListener('resize', handleResize);

      // Call handleResize initially to set the size based on the initial window size
      handleResize();

      return () => {
      };
    }
  }, [data]);

    useEffect(() => {
      // Update for Main Chart Data
      if (!chartRef.current || !data || data.length === 0) return;

      SERIES.forEach(seriesInfo => {
        const series = seriesRef.current[seriesInfo.key];
        if (series) {
          let newData;
          // Always slice the data to the current replay index
          const slicedData = data.slice(0, replayIndex + 1);

          if (seriesInfo.type === 'candlestick') {
            newData = transformDataForCandlestick(slicedData);
          } else {
            newData = transformDataForSeries(slicedData, seriesInfo.key);
          }
          series.setData(newData);
        }
              // Set the range for the chart
        if (chartRef.current) {
          const timeScale = chartRef.current.timeScale();

          // For example, to keep the chart scrolled to the latest data point:
          timeScale.scrollToPosition(-1, false);
        }
      });



    }, [data, replayIndex]); // Note that isReplaying is removed from dependencies


    useEffect(() => {
      if (!chartRef.current) return;

      // Assume your Zustand store has a method to get the current data entry for replayIndex
      const currentDataEntry = useWebSocketDataStore.getState().data[replayIndex];
      const closeValue = currentDataEntry ? currentDataEntry.close : null;

      // Now call createSRSeries with the closeValue parameter
      const srSeriesConfigurations = createSRSeries(optionsData, replayIndex, closeValue);

      srSeriesConfigurations.forEach(seriesConfig => {
        let series = seriesRef.current[seriesConfig.key];
        if (!series) {
          series = chartRef.current.addLineSeries(seriesConfig.chartOptions);
          seriesRef.current[seriesConfig.key] = series;
        }

        // The logic for setting series data remains the same
        const seriesData = isReplaying
          ? [{ time: replayIndex, value: Number(seriesConfig.price) }]
          : [{ time: 1, value: Number(seriesConfig.price) }];

        series.setData(seriesData);
      });
    }, [optionsData, isReplaying, replayIndex]); // Ensure dependencies are correctly listed

    useEffect(() => {
      // Update for Toggles Visibility
      if (!chartRef.current) return;

      Object.keys(seriesRef.current).forEach(seriesKey => {
        const series = seriesRef.current[seriesKey];
        if (series) {
          series.applyOptions({ visible: toggles[seriesKey] });
        }
      });
    }, [toggles, isReplaying]);


    useEffect(() => {
        if (!correlations || Object.keys(correlations).length === 0 || !trendChartContainerRef.current) {
            return;
        }

        if (!trendChartRef.current) {
            trendChartRef.current = createChart(trendChartContainerRef.current, {
                width: window.innerWidth * 0.95,
                height: window.innerHeight * 0.50,
                alignLabels: true,
                timeScale: {
                    rightOffset: 12,
                    barSpacing: 0,
                    shiftVisibleRangeOnNewBar: false,
                    fixLeftEdge: true,
                    fixRightEdge: false,
                    rightBarStaysOnScroll: true,
                    leftBarStaysOnScroll: true,
                    borderVisible: false,
                    borderColor: "#000000",
                    timeVisible: true,
                    secondsVisible: false,
                },
                leftPriceScale: {
                    borderVisible: false,
                    visible: true,
                    mode: PriceScaleMode.Normal,
                    scaleMargins: { top: 0.1, bottom: 0.1 },
                },
                rightPriceScale: {
                    borderVisible: false,
                    mode: PriceScaleMode.Normal,
                    scaleMargins: { top: 0.1, bottom: 0.1 },
                },
                grid: {
                    vertLines: { color: 'rgba(42, 46, 57, 0)' },
                    horzLines: { color: '#000000' },
                },
                layout: {
                    background: { color: '#000000' },
                    textColor: '#d1d4dc',
                },
                crosshair: {
                    mode: CrosshairMode.Normal,
                    vertLine: {
                        width: 8,
                        color: '#C3BCDB44',
                        style: LineStyle.Solid,
                        labelBackgroundColor: '#B18800',
                    },
                    horzLine: {
                        color: '#C3BCDB44',
                        labelBackgroundColor: '#B18800',
                    },
                },
            });

            let currentSeries = null;
            let currentTrend = correlations.correlations20[0]?.trend;
            let segmentData = [];

            const topLineSeries = trendChartRef.current.addLineSeries({
                color: 'rgb(255, 255, 255)', // Consistent color for top line
                lineWidth: 1,
                priceScaleId: 'right',
                priceLineVisible: false, // Disable the line at the last value
                lastValueVisible: true, // Disable visibility of the last value
            });


            // Explicitly add topLineSeries to trendSeriesRef for crosshair use
            trendSeriesRef.current['topLine'] = topLineSeries;

            const topLineData = []; // Data for continuous top line

            const setSeriesData = (series, data) => {
                series.setData(data.sort((a, b) => a.time - b.time));
            };

            correlations.correlations20.forEach((dataPoint, index) => {
                const { trend, time, value } = dataPoint;
                const adjustedTime = time !== 0 ? time : correlations.correlations20[0].time + index * 60;

                // Continuously add points to the top line series
                topLineData.push({ time: adjustedTime, value });

                if (trend !== currentTrend) {
                    // Finalize the current segment with a connecting point
                    if (currentSeries && segmentData.length > 0) {
                        segmentData.push({
                            time: adjustedTime,
                            value: segmentData[segmentData.length - 1].value, // Connecting point
                        });
                        setSeriesData(currentSeries, segmentData);
                    }

                    // Start a new series for the new trend
                    currentSeries = trend === 'call'
                        ? trendChartRef.current.addAreaSeries(TRENDSERIES_CALL_LEADING.chartOptions)
                        : trendChartRef.current.addAreaSeries(TRENDSERIES_PUT_LEADING.chartOptions);

                    segmentData = [{ time: adjustedTime, value }];
                    currentTrend = trend;
                } else {
                    segmentData.push({ time: adjustedTime, value });
                }
            });

            // Finalize the last series segment
            if (currentSeries && segmentData.length > 0) {
                setSeriesData(currentSeries, segmentData);
            }

            // Set data for the continuous top line series to ensure no gaps
            topLineSeries.setData(topLineData);

            // Add horizontal lines to the trend chart
            HORIZONTAL_LINES.forEach(line => {
                const lineSeries = trendChartRef.current.addLineSeries({
                    color: line.color,
                    priceLineVisible: true,
                    lineWidth: 3, // Increase line thickness
                    lineStyle: LineStyle.Solid // Ensure solid line style, if available
                });

                // Set the line as a single point, which will draw it across the entire chart
                lineSeries.setData([{ time: 0, value: line.price }]);
            });


            // Handle resizing of the trend chart
            const handleResize = () => {
                const newWidth = window.innerWidth * 0.95;
                const newHeight = window.innerHeight <= 1080 ? window.innerHeight * 0.20 : window.innerHeight * 0.20;
                trendChartRef.current.resize(newWidth, newHeight);
                const timeScale = trendChartRef.current.timeScale();
                timeScale.setVisibleLogicalRange({ from: 0, to: 410 });
            };

            window.addEventListener('resize', handleResize);
            handleResize();

            return () => {
                window.removeEventListener('resize', handleResize);
                trendChartRef.current.remove();
                trendChartRef.current = null;
            };
        }
    }, [correlations]);


    // Crosshair synchronization effect
    useEffect(() => {
        const mainChart = chartRef.current;
        const trendChart = trendChartRef.current;

        const cleanupSync = () => {
            if (mainChart) {
                mainChart.timeScale().unsubscribeVisibleLogicalRangeChange(syncTimeRange);
                mainChart.unsubscribeCrosshairMove(handleMainChartCrosshairMove);
            }
            if (trendChart) {
                trendChart.timeScale().unsubscribeVisibleLogicalRangeChange(reverseSyncTimeRange);
                trendChart.unsubscribeCrosshairMove(handleTrendChartCrosshairMove);
            }
        };

        const syncTimeRange = (timeRange) => {
            if (trendChart) {
                trendChart.timeScale().setVisibleLogicalRange(timeRange);
            }
        };

        const reverseSyncTimeRange = (timeRange) => {
            if (mainChart) {
                mainChart.timeScale().setVisibleLogicalRange(timeRange);
            }
        };

        const handleMainChartCrosshairMove = (param) => {
            const dataPoint = getCrosshairDataPoint(seriesRef.current['candlestick'], param);
            if (dataPoint && trendChart) {
                syncCrosshair(trendChart, trendSeriesRef.current['topLine'], dataPoint);
            } else if (trendChart) {
                trendChart.clearCrosshairPosition();
            }
        };

        const handleTrendChartCrosshairMove = (param) => {
            const dataPoint = getCrosshairDataPoint(trendSeriesRef.current['topLine'], param);
            if (dataPoint && mainChart) {
                syncCrosshair(mainChart, seriesRef.current['candlestick'], dataPoint);
            } else if (mainChart) {
                mainChart.clearCrosshairPosition();
            }
        };

        if (!isReplaying && mainChart && trendChart && seriesRef.current['candlestick'] && trendSeriesRef.current['topLine']) {
            mainChart.timeScale().subscribeVisibleLogicalRangeChange(syncTimeRange);
            trendChart.timeScale().subscribeVisibleLogicalRangeChange(reverseSyncTimeRange);
            mainChart.subscribeCrosshairMove(handleMainChartCrosshairMove);
            trendChart.subscribeCrosshairMove(handleTrendChartCrosshairMove);
        }

        return cleanupSync;
    }, [data, correlations, isReplaying]);

    function getCrosshairDataPoint(series, param) {
        if (!param || !param.time || !param.seriesData || typeof param.seriesData.get !== 'function') {
            return null;
        }

        const dataPoint = param.seriesData.get(series);
        if (dataPoint) {
            return dataPoint.close !== undefined
                ? { time: param.time, value: dataPoint.close }
                : { time: param.time, value: dataPoint.value };
        }

        return null;
    }

    function syncCrosshair(chart, series, dataPoint) {
        if (dataPoint && dataPoint.time !== undefined && dataPoint.value !== undefined && chart) {
            try {
                chart.setCrosshairPosition(dataPoint.value, dataPoint.time, series);
            } catch (error) {
                chart.clearCrosshairPosition();
            }
        } else if (chart) {
            chart.clearCrosshairPosition();
        }
    }

    const renderLabelWithColor = (seriesKey, labelText) => {
      const series = SERIES.find(s => s.key === seriesKey);
      let color;
      if (series) {
        color = series.key === 'total_gex' ? series.chartOptions.topColor : series.chartOptions.color;
      } else {
        color = 'transparent'; // Fallback to 'transparent' if series not found
      }
      return (
        <span>
          <span style={{
            backgroundColor: color,
            width: '10px',
            height: '10px',
            display: 'inline-block',
            marginRight: '5px'
          }} />
          {series.buttonLabel}
        </span>
      );
    };

    return (
        <div className="chart-component-container">
            Static message section
            <div className="message-section"></div>

            <div className="toggle-buttons-container">
                {SERIES.map((series) => (
                    <SeriesToggleButton
                        key={series.key}
                        seriesKey={series.key}
                        label={renderLabelWithColor(series.key, series.chartOptions.title)}
                        className="indicator"
                    />
                ))}
            </div>

            <div className="content-container">
                <div className="chart-container" style={{ position: 'relative' }}>
                    <div ref={chartContainerRef} /> {/* Main chart container */}
                    <div className="overlay">0 DTE Dashboard</div> {/* Overlay for Main Chart */}
                </div>

                <div className="trend-chart-container" style={{ position: 'relative' }}>
                    <div ref={trendChartContainerRef} /> {/* Trend chart container */}
                    <div className="overlay">GEX Trend Strength</div> {/* Overlay for Trend Chart */}
                </div>
            </div>
        </div>
    );

};

export default ChartComponent;
