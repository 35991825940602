import React, { useEffect } from 'react';
import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';

const CorrelationComponent = () => {
    const data = useWebSocketDataStore(state => state.data);
    const replayIndex = useWebSocketDataStore(state => state.replayIndex);
    const setCorrelations = useWebSocketDataStore(state => state.setCorrelations);

    const applySmoothingFilter = (data, alpha = 0.2) => {
        const a1 = Math.exp(-1.414 * Math.PI * alpha);
        const b1 = 2 * a1 * Math.cos(1.414 * Math.PI * alpha);
        const c2 = b1;
        const c3 = -a1 * a1;
        const c1 = 1 - c2 - c3;

        const smoothedData = [];
        smoothedData[0] = 0; // Start the first value at 0
        smoothedData[1] = data[1]; // Initialize the second value normally

        for (let i = 2; i < data.length; i++) {
            smoothedData[i] = c1 * data[i] + c2 * smoothedData[i - 1] + c3 * smoothedData[i - 2];
        }

        return smoothedData;
    };

    useEffect(() => {
        if (data && data.length > 0 && replayIndex < data.length) {
            const sliceEnd = replayIndex + 1;
            const slicedData = data.slice(0, sliceEnd);
            const trendStrengths = [];

            const callVolumeGamma = slicedData.map(row => ({ time: row.time, value: row.total_call_volume_gamma }));
            const putVolumeGamma = slicedData.map(row => ({ time: row.time, value: row.total_put_volume_gamma }));

            // Set initial value to 0 for the first data point and neutral trend
            trendStrengths.push({ time: callVolumeGamma[0]?.time || 0, value: 0, trend: 'neutral' });

            // Calculate leading GEX trend and its strength for subsequent points
            for (let i = 1; i < callVolumeGamma.length; i++) {
                const callGEX = callVolumeGamma[i].value;
                const putGEX = putVolumeGamma[i].value;

                if (callGEX === 0 && putGEX === 0) {
                    // Both GEX values are zero
                    trendStrengths.push({ time: callVolumeGamma[i].time, value: 0, trend: 'neutral' });
                } else if (callGEX > putGEX) {
                    // Call GEX is leading
                    const strength = (callGEX - putGEX) / (callGEX + putGEX || 1); // Avoid division by zero
                    trendStrengths.push({ time: callVolumeGamma[i].time, value: Math.max(strength, 0), trend: 'call' });
                } else {
                    // Put GEX is leading
                    const strength = (putGEX - callGEX) / (callGEX + putGEX || 1); // Avoid division by zero
                    trendStrengths.push({ time: putVolumeGamma[i].time, value: Math.max(strength, 0), trend: 'put' });
                }
            }

            // Apply smoothing to the trend strengths
            const smoothedStrengths = applySmoothingFilter(trendStrengths.map(d => d.value));

            // Combine smoothed strengths back with the trend and time data
            const smoothedTrendData = trendStrengths.map((d, index) => ({
                time: d.time,
                value: smoothedStrengths[index],
                trend: d.trend // Retain the 'call' or 'put' trend type
            }));

            // Set the resulting data
            setCorrelations({ correlations20: smoothedTrendData });
        } else {
            console.log('No data available for calculations or replayIndex out of bounds');
        }
    }, [data, replayIndex, setCorrelations]);


    return null;
};

export default CorrelationComponent;

//Current working version not fixed for 0 values. 
// import React, { useEffect } from 'react';
// import useWebSocketDataStore from '../Store/WebSocketDataStore.jsx';

// const CorrelationComponent = () => {
//     const data = useWebSocketDataStore(state => state.data);
//     const replayIndex = useWebSocketDataStore(state => state.replayIndex);
//     const setCorrelations = useWebSocketDataStore(state => state.setCorrelations);

//     const applySmoothingFilter = (data, alpha = 0.2) => {
//         const a1 = Math.exp(-1.414 * Math.PI * alpha);
//         const b1 = 2 * a1 * Math.cos(1.414 * Math.PI * alpha);
//         const c2 = b1;
//         const c3 = -a1 * a1;
//         const c1 = 1 - c2 - c3;

//         const smoothedData = [];
//         smoothedData[0] = 0; // Start the first value at 0
//         smoothedData[1] = data[1]; // Initialize the second value normally

//         for (let i = 2; i < data.length; i++) {
//             smoothedData[i] = c1 * data[i] + c2 * smoothedData[i - 1] + c3 * smoothedData[i - 2];
//         }

//         return smoothedData;
//     };

//     useEffect(() => {
//         if (data && data.length > 0 && replayIndex < data.length) {
//             const sliceEnd = replayIndex + 1;
//             const slicedData = data.slice(0, sliceEnd);
//             const trendStrengths = [];

//             const callVolumeGamma = slicedData.map(row => ({ time: row.time, value: row.total_call_volume_gamma }));
//             const putVolumeGamma = slicedData.map(row => ({ time: row.time, value: row.total_put_volume_gamma }));

//             // Set initial value to 0 for the first data point and neutral trend
//             trendStrengths.push({ time: callVolumeGamma[0]?.time || 0, value: 0, trend: 'neutral' });

//             // Calculate leading GEX trend and its strength for subsequent points
//             for (let i = 1; i < callVolumeGamma.length; i++) {
//                 const callGEX = callVolumeGamma[i].value;
//                 const putGEX = putVolumeGamma[i].value;

//                 if (callGEX > putGEX) {
//                     // Call GEX is leading
//                     const strength = (callGEX - putGEX) / (callGEX + putGEX); // Normalized strength
//                     trendStrengths.push({ time: callVolumeGamma[i].time, value: strength, trend: 'call' });
//                 } else {
//                     // Put GEX is leading
//                     const strength = (putGEX - callGEX) / (callGEX + putGEX); // Normalized strength
//                     trendStrengths.push({ time: putVolumeGamma[i].time, value: strength, trend: 'put' });
//                 }
//             }

//             // Apply smoothing to the trend strengths
//             const smoothedStrengths = applySmoothingFilter(trendStrengths.map(d => d.value));

//             // Combine smoothed strengths back with the trend and time data
//             const smoothedTrendData = trendStrengths.map((d, index) => ({
//                 time: d.time,
//                 value: smoothedStrengths[index],
//                 trend: d.trend // Retain the 'call' or 'put' trend type
//             }));

//             // Set the resulting data
//             setCorrelations({ correlations20: smoothedTrendData });
//         } else {
//             console.log('No data available for calculations or replayIndex out of bounds');
//         }
//     }, [data, replayIndex, setCorrelations]);

//     return null;
// };

// export default CorrelationComponent;













